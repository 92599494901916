const HOST = "https://devlogex.com";

export const fetchBlogPosts = async () => {
  try {
    const response = await fetch(`${HOST}/api/blog/article/`);
    return response.json();
  } catch (error) {
    console.error(error);
  }
};

export const fetchBlogPost = async (slug) => {
  try {
    const response = await fetch(`${HOST}/api/blog/article/${slug}/`);
    return response.json();
  } catch (error) {
    console.error(error);
  }
};
