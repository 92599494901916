import React, { useEffect, useState } from "react";
import { fetchBlogPosts } from "@/fetchers";
import { PostItem } from "@/components";
import "./style.css";

const BlogList = ({ className = "" }) => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const loadPosts = async () => {
      const posts = await fetchBlogPosts();
      console.log(posts[0]);
      setPosts(posts);
    };

    loadPosts();
  }, []);

  return (
    <div className={`container ${className}`} id="blog-list">
      <div className="section">
        <div className="container">
          <div className="row">
            {posts.map((post, index) => (
              <PostItem key={index} post={post} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogList;
