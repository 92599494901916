import React from "react";
import "./style.css";

const Footer = () => (
  <footer>
    <p>&copy; 2024 Devlogex. All rights reserved.</p>
  </footer>
);

export default Footer;
