import React, { useEffect, useState, lazy } from "react";
import { useParams } from "react-router-dom";
import { fetchBlogPost } from "@/fetchers";
import "./style.css";
import { formatDate } from "@/utils/common.utils";

const RichText = lazy(() => import("@/components/RichText"));

const PostPage = (props) => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const loadPost = async () => {
      const post = await fetchBlogPost(slug);
      setPost(post);
      console.log(post);
    };

    loadPost();
  }, [slug]);

  if (!post) return <p>Loading...</p>;

  return (
    <div id="post-page">
      <div className="container section post-section bg-white">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="text-center">
              <img
                src="assets/person_1.jpg"
                alt=""
                className="author-pic img-fluid rounded-circle mx-auto"
              />
            </div>
            <h2 className="heading text-center">{post?.title}</h2>
            <span className="d-block text-center">{post?.author}</span>
            <span className="date d-block text-center small text-uppercase text-black-50 mb-5">
              {formatDate(post?.updated_at)}
            </span>

            <RichText richText={post.content} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostPage;
